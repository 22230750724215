<template>
  <div class="column is-one-third">
    <div class="card">
      <div class="card-content">
        <div class="media">
          <div class="media-content">
            <p class="title is-4">
              {{ timeLeft }}
              <button class="is-pulled-right button px-3" @click="deleteTimer">
                <i class="fa-solid fa-trash" />
              </button>
            </p>
            <p class="subtitle is-6">{{ originalTime }}</p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "Timer",
  data() {
    return {
      timeLeft: "",
      originalTime: "",
    };
  },
  methods: {
    deleteTimer() {
      this.$emit("deleteMe", this.timer);
    },
    update() {
      this.timeLeft = "";
      let left = this.timer.expires - Date.now();
      const items = [];
      items.push(left % 1000);
      left -= items.slice(-1);
      left = left / 1000;

      items.push(Math.floor(left) % 60);
      left -= items.slice(-1);
      items.push(Math.floor(left / 60) % 60);
      left -= items.slice(-1) * 60;
      items.push(Math.floor(left / 60 / 60));
      const timeLeft = items
        .map((value, index) =>
          items.length - 1 == index
            ? value
            : value.toLocaleString("en-US", {
                minimumIntegerDigits: index == 0 ? 3 : 2,
              })
        )
        .reverse()
        .join(":")
        .replace(/^0:/, "");
      this.timeLeft =
        timeLeft.substring(0, timeLeft.lastIndexOf(":")) +
        "." +
        timeLeft.substring(timeLeft.lastIndexOf(":") + 1);
      if (this.timer.expires - Date.now() > 0)
        requestAnimationFrame(this.update);
      else {
        this.timeLeft = "00:00.000";
      }
    },
  },
  mounted() {
    this.update();
    let orig = this.timer.originalTime;
    let items = [];
    items.push(Math.floor(orig) % 60);
    orig -= items.slice(-1);
    items.push(Math.floor(orig / 60) % 60);
    orig -= items.slice(-1) * 60;
    items.push(Math.floor(orig / 60 / 60));

    this.originalTime = items
      .map((value, index) =>
        items.length - 1 == index
          ? value
          : value.toLocaleString("en-US", {
              minimumIntegerDigits: 2,
            })
      )
      .reverse()
      .join(":")
      .replace(/^0:/, "");
  },
  props: {
    timer: Object,
  },
};
</script>

<style scoped></style>
