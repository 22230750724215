<template>
  <div>
    <nav class="navbar" role="navigation" aria-label="main navigation">
      <div class="navbar-brand">
        <span class="navbar-item" href="https://bulma.io">
          xX_DojnazINSANE__MultiTimer_Xx
        </span>
      </div>
    </nav>
    <section class="section">
      <div class="container">
        <div class="columns is-multiline">
          <Timer
            v-for="timer in timers"
            :key="timer.expires"
            :timer="timer"
            @deleteMe="deleteTimer"
          />
        </div>
        <div class="field has-addons">
          <div class="control">
            <input
              class="input"
              v-model="newTimer"
              :class="isValid ? '' : 'is-danger'"
              type="text"
              placeholder="10:00"
            />
          </div>
          <div class="control">
            <button
              class="button is-info"
              @click="addTimer"
              :disabled="!isValid"
            >
              Add
            </button>
          </div>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
import Timer from "./components/Timer.vue";

export default {
  data() {
    return {
      timers: JSON.parse(localStorage.getItem("timers") || "[]"),
      newTimer: "",
      isValid: false,
    };
  },
  name: "App",
  components: {
    Timer,
  },
  methods: {
    deleteTimer(timer) {
      if (this.timers.includes(timer)) {
        this.timers.splice(this.timers.indexOf(timer), 1);
      }
      localStorage.setItem("timers", JSON.stringify(this.timers));
    },
    addTimer() {
      const parts = this.newTimer.split(":").reverse();

      let time = parseInt(parts[0]);
      if (parts[1]) time += parseInt(parts[1]) * 60;
      if (parts[2]) time += parseInt(parts[2]) * 60 * 60;
      this.timers.push({
        expires: Date.now() + time * 1000,
        originalTime: time,
      });

      localStorage.setItem("timers", JSON.stringify(this.timers));
    },
  },
  watch: {
    newTimer(newValue) {
      this.isValid = /^(\d{1,}:)?((?:[0-5]\d):)?(?:[0-5]\d?)$/.test(newValue);
    },
  },
  mounted() {
    addEventListener("storage", () => {
      this.timers = JSON.parse(localStorage.getItem("timers") || "[]");
    });
  },
};
</script>

<style scoped></style>
